.blog-heading {
    color: #fef9c3;
    margin-top: 128px;
    font-size: 3em;
}

.blog-subheading {
    color: #fef9c3;
    padding: 16px;
    font-style: italic;
}

.blog-metadata {
    color: #ffffff;
    padding: 8px;
    text-align: end;
    font-size: small;
}

.regular-paragraph {
    color: white;
    text-align: justify;
    padding: 16px;
}

.paragraph-heading {
    color: white;
    text-align: start;
    padding: 16px;
    padding-bottom: 0px;
    font-size: 1.5em;
    font-weight: 700;
}

.blog-image {
    padding: 16px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}