.App {
  text-align: center;
  overflow-x: hidden;
}

.App-header {
  background-color: #27272a;
  height: 100vh;
  width: 100vw;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.gradient-background {
  min-height: 100vh;
  min-width: 100vw;
  background: conic-gradient(
          at 70% 55%,
          #FF4545 90deg,
          #67C8FF 170deg,
          #67C8FF 210deg,
          #f5873c 280deg,
          #67C8FF 354deg,
          #04ff4b 355deg,
          #FF4545 357deg
        );
}

.rounded-container {
  border-radius: 100px;
  background-color: #fef9c3;
  min-height: 130px;
}

.square-container {
  border-radius: 5px;
  background-color: #fef9c3;
  min-height: 130px;
}

.text-clear {
  opacity: 0;
  transition: 500ms;
  padding: 12pt;
  font-weight: bold;
}

.text-opaque {
  opacity: 1;
  transition: 400ms;
  padding: 12pt;
  font-weight: bold;
  color: white;
}

.starter-square {
  border-radius: 10px;
  height: 50pt;
  width: 3pt;
  transition: all 200ms ease;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fef9c3;
}

.phone-screen {
  border-radius: 10px;
  height: 300pt;
  width: 150pt;
  min-width: 150pt;
  transition: all 200ms ease;
}

.laptop-screen {
  border-radius: 10px;
  height: 200pt;
  width: 400pt;
  min-width: 400pt;
  transition: all 200ms ease;
}

.cloud-stack {
  border-radius: 10px;
  height: 200pt;
  width: 200pt;
  min-width: 200pt;
  margin-right: 16pt;
  transition: all 200ms ease;
}

.automation-clock {
  border-radius: 100%;
  height: 200pt;
  width: 200pt;
  min-width: 200pt;
  transition: all 200ms ease;
}

.clock-off {
  border-radius: 100%;
  height: 0;
  width: 0;
  transition: all 200ms ease;
}

:root {
  --main-color: #fef9c3;
  --main-color-hover: #f7f3ce39;
  --track-color: #555555;
}

.customSlider {
  /* max width of your slider */
  max-width: 300px;
  /* Optional: Only If you want to center your slider in the page */
  margin: auto;
}

.customSlider-track {
  /* Top value to align your track to the center of your thumb */
  top: 8px;
  /* thickness of the track */
  height: 4px;
 /* default color of your track */
  background: var(--track-color);
}

.customSlider-track.customSlider-track-0 {
  /* color of the track before the thumb */
  background: var(--main-color);
}

.customSlider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background: var(--main-color);
  /* shape of the thumb: circle */
  width: 20px;
  height: 20px;
  border-radius: 100%;
  /* remove default outline when selected */
  outline: none;
}

.customSlider-thumb:hover {
  box-shadow: 0 0 0 8px var(--main-color-hover);
}

.logo {
  max-height: 50px;
  max-width: 50px;
}